<footer class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="footer-area-content">
                    <img
                        src="assets/img/logo/logo-i3o.png"
                        alt="logo"
                        class="main-logo"
                    />
                    <p>
                        Interest in IoT technology has also experienced a
                        significant increase from discussions about short-range
                        wireless communications, sensor networks and so on.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Information Link</h3>

                    <ul class="footer-quick-links">
                        <li>
                            <a href="https://iysa.or.id" target="_blank"
                                >Our Main Website</a
                            >
                        </li>
                        <!-- <li>
                            <a href="https://registrasi-i3o-2024.vercel.app/" target="_blank">Registration Link</a>
                        </li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Our Information</h3>

                    <ul class="footer-quick-links address-link">
                        <li>
                            <a href="https://goo.gl/maps/gEzFyE9gtXEyRfA26"
                                ><i class="fa fa-map-marker"></i> Jl. Kemang RT
                                03 RW 06</a
                            >
                        </li>
                        <li>
                            <a href="mailto:i3o.iysa@gmail.com"
                                ><i class="fa fa-envelope"></i>
                                i3o.iysa@gmail.com</a
                            >
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send/?phone=6288213248890&text&type=phone_number&app_absent=0"><i class="fa fa-phone"></i> 0882 1324 8890 </a>
                            <a href="https://api.whatsapp.com/send/?phone=6281770914129&text&type=phone_number&app_absent=0"><i class="fa fa-phone"></i> 0817 7091 4129</a>
                        </li>
                    </ul>

                    <ul class="social-list">
                        <li>
                            <a
                                href="https://www.facebook.com/Indonesia-Young-scientist-Association-106523338057326"
                                class="facebook"
                                target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://instagram.com/iysa_official"
                                class="instagram"
                                target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>
                        <i class="far fa-copyright"></i>2022 I3O Official. All
                        Rights Reserved
                    </p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

<div class="sidebar-modal">
    <div
        class="modal right fade"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <i class="fa fa-times"></i>
                        </span>
                    </button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/">
                            <img src="assets/img/logo/logo.png" alt="Logo" />
                        </a>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>
